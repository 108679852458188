import React from 'react';
import { CustomerDetails } from '../models/customerDetails.model';
import { FakeDb } from '../FakeDB/fakedb';
import Header from '../components/Header';
import Seals from '../components/Seals';
import Footer from '../components/Footer';
import MapSmall from '../components/MapSmall';
import DoctorCard from '../components/DoctorCard';
import DoctorOverview from '../components/DoctorOverview';
import ImageGallery from '../components/ImageGallery';

interface Props {}

interface State {
    customer: CustomerDetails;
    showText: boolean;
}

class DoctorPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            customer: FakeDb.customerDetails[0],
            showText: false
        };
    }

    componentDidMount() {
        let customerID = window.location.pathname.split('/')[2];
        console.log(customerID);
        //get customerDetails by customerID
    }

    render() {
        const { customer } = this.state;
        const style = {
            customerTheme: {
                background: customer.themeColor
            }
        };

        return (
            <div className='doctor-page'>
                <section className='hero' style={style.customerTheme}>
                    <Header />

                    <div className='intro'>
                        <h1 className='headline'>{customer.name}</h1>
                        <p className='subheader'>Buchen Sie Ihren nächsten Termin bei uns direkt hier</p>
                    </div>

                    <img className='bg bottom' src={require('../assets/images/backgrounds/welle_1.png')} alt='' />
                </section>

                <section className='informations'>
                    <DoctorOverview customer={customer} color={style.customerTheme.background} />

                    <MapSmall position={[customer.address.latitude, customer.address.longitude]} />
                </section>

                <section className='about'>
                    <h4>Über uns</h4>
                    <p>{customer.aboutUs}</p>
                </section>

                <section className='doctors'>
                    <h3>Unsere Ärzte</h3>
                    <div className='doctors-container'>
                        {customer.doctors.map((d, i) => (
                            <DoctorCard key={i} doctor={d} backgroundColor={style.customerTheme.background} />
                        ))}
                    </div>
                </section>

                <section className='surgery-images'>
                    <h3>Unsere Praxis</h3>
                    <ImageGallery images={customer.surgeryImages} />
                </section>

                <Seals />

                <Footer style={style.customerTheme} />
            </div>
        );
    }
}

export default DoctorPage;
