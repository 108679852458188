import React from 'react';
import { Link } from 'react-router-dom';

// TODO: add
interface Props {
    style?: Object;
}

const Footer: React.FC<Props> = (props) => {
    return (
        <div className="footer-wrapper" style={props.style}>
            <img className="bg top" src={require('../assets/images/backgrounds/welle_2.png')} alt="" />
            <div className="footer">
                <div className="brand">
                    <div className="icon-logo-white"></div>
                    <div className="brand-title">Dr. Flex GmbH</div>
                </div>
    
                <div>
                    <div>Neumarkt 2a</div>
                    <div>59320 Ennigerloh</div>
                </div>
    
                <div>
                    <div>info@dr-flex.de</div>
                    <div>02587 979 97 90</div>
                </div>
    
                <div>© 2015 - 2019 Dr. Flex GmbH</div>
    
                <div>
                    <Link to="/agb-aerzte" target="_blank" rel="noopener noreferrer">AGB für Ärzte</Link>
                    <Link to="/agb-patienten" target="_blank" rel="noopener noreferrer">AGB für Patienten</Link>
                    <Link to="/impressum" target="_blank" rel="noopener noreferrer">Impressum</Link>
                    <Link to="/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">Datenschutz</Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;
