export enum MedicalSpecialityEnum {
    ZAHNMEDIZIN = 'Zahnmedizin',
    ORTHOPÄDE = 'Orthopäde',
    FRAUENARZT = 'Frauenarzt',
    AKUPUNKTUR = 'Akupunktur',
    FUSSCHIRURG = 'Fußchirurg',
}

export interface Customer {
    id: number;
    customerID: number;
    name: string;
    address: Address;
    contact: Contact;
    appointments: string[];
    brandImage: string;
    medicalSpeciality: MedicalSpecialityEnum;
}

export interface Address {
    street: string;
    number: string;
    zipCode: string;
    city: string;
    latitude: number;
    longitude: number;
}

export interface Contact {
    email: string;
    telephone: string;
    website: string;
}