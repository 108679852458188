import React from 'react';

interface State {}

const Seals: React.FC = () => {
    return (
        <section className="icon-row seals">
            <h3 className="headline">Dabei steht die Sicherheit Ihrer Daten an oberster Stelle</h3>
            <div>
                <img src={require('../assets/icons/DSGVO.svg')} alt="DSGVO Konform"/>
                <h4>DSGVO Konform</h4>
            </div>

            <div>
                <img src={require('../assets/icons/Server.svg')} alt="Server in Deutschland"/>
                <h4>Server in Deutschland</h4>
            </div>

            <div>
                <img src={require('../assets/icons/SSL.svg')} alt="SSL Verschlüsselung"/>
                <h4>SSL Verschlüsselung</h4>
            </div>

            <div>
                <img src={require('../assets/icons/Beratung.svg')} alt="100% Persönliche Beratung und Support"/>
                <h4>100% Persönliche Beratung und Support</h4>
            </div>
        </section>
    );
};

export default Seals;