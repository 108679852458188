import React from 'react';
import L, { LatLngExpression } from 'leaflet';


interface Props {
    position: LatLngExpression;
}

class MapSmall extends React.Component<Props> {

    componentDidMount() {
        // create map
        let map = L.map('map', {
            zoom: 16,
            center: this.props.position,
            layers: [L.tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png')],
            zoomControl: false, //hide default controls
        });

        L.control
            .zoom({
                position: 'topright'
            })
            .addTo(map);

        let markerIcon = L.icon({
            iconUrl: require('../assets/icons/marker_active.svg')
        });

        // add marker
        L.marker(this.props.position, { icon: markerIcon }).addTo(map);

    }

    render() {
        return (
            <div id="map" className="map small"></div>
        );
    }
}

export default MapSmall;
