import React from 'react';
import moment from 'moment';

import { Customer } from '../models/customer.model';
import { Link } from 'react-router-dom';
import { DOCTOR } from '../constants/Path';

interface Props {
    customer: Customer;
    history?: any;
    onClickCard: () => void;
}

const SurgeryCard: React.FC<Props> = props => {
    const c = props.customer;

    return (
        <div className='surgery-card' onClick={props.onClickCard}>
            <h4 className='name'>{c.name}</h4>
            <img className='brand' src={c.brandImage} alt={c.name + ' Logo'} />
            <p className='address'>{`${c.address.street} ${c.address.number}, ${c.address.zipCode} ${c.address.city}`}</p>
            <p><label className='medicalSpeciality'>{c.medicalSpeciality}</label></p>
            <div className='divider'></div>
            <p >Unsere nächsten freien Termine:</p>
            <div className='appointments'>
                {c.appointments.map((a, index) => (
                    <div key={index}>{moment(a).format('D.MM.')}</div>
                ))}
            </div>
            <Link to={`${DOCTOR}/${c.customerID}`} className='primary_btn ghost-green'>
                Zur Terminbuchung
            </Link>
        </div>
    );
};

export default SurgeryCard;
