import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROOT } from '../constants/Path';

interface MenuButtonProps {
    open: boolean;
    onClick: (event: any) => void;
}

interface SubmenuProps {
    isOpen: boolean;
    onLeave: (event: any) => void;
}

interface OverlayProps {
    showOverlay: boolean;
}

interface HeaderState {
    isOpen: boolean;
    showSubmenu: boolean;
}

interface HeaderProps {
    onSearch?: (event: any) => void;
    showSearchInput?: boolean;
}

function setbgColor(): string {
    let bgColor;
    switch (window.location.pathname) {
        case '/':
            bgColor = 'linear-gradient(-225deg, #0084f7 0%, #7ee8ea 100%)';
            break;
        case '/aktuelles':
            bgColor = 'linear-gradient(-225deg, #ffa400 0%, #ffbf49 100%)';
            break;
        default:
            bgColor = 'linear-gradient(-225deg, #7eeaa6 0%, #00ca9d 100%)';
            break;
    }
    return bgColor;
}

const MenuButton: React.FC<MenuButtonProps> = props => {
    const styles = {
        lineTop: {
            transform: props.open ? 'rotate(45deg)' : 'none',
            transformOrigin: 'top left',
            marginBottom: '5px'
        },
        lineMiddle: {
            opacity: props.open ? 0 : 1,
            transform: props.open ? 'translateX(-16px)' : 'none'
        },
        lineBottom: {
            transform: props.open ? 'translateX(-1px) rotate(-45deg)' : 'none',
            transformOrigin: 'top left',
            marginTop: '5px'
        }
    };

    return (
        <div className='menuButton' onClick={props.onClick}>
            <div className='line' style={styles.lineTop} />
            <div className='line' style={styles.lineMiddle} />
            <div className='line' style={styles.lineBottom} />
        </div>
    );
};

const Submenu: React.FC<SubmenuProps> = props => {
    return props.isOpen ? (
        <ul className='submenu' onMouseLeave={props.onLeave}>
            <li>
                <NavLink activeClassName='is-active' exact to={''}>
                    Praxiskalender
                </NavLink>
            </li>
            <li>
                <NavLink activeClassName='is-active' exact to={''}>
                    Patientenservice
                </NavLink>
            </li>
            <li>
                <NavLink activeClassName='is-active' exact to={''}>
                    Individuelle Lösung
                </NavLink>
            </li>
            <li>
                <NavLink activeClassName='is-active' exact to={''}>
                    Zeitgewinn durch Entlastung
                </NavLink>
            </li>
            <li>
                <NavLink activeClassName='is-active' exact to={''}>
                    Erinnerungsservice
                </NavLink>
            </li>
        </ul>
    ) : null;
};

const Overlay: React.FC<OverlayProps> = props => {
    const styles = {
        overlay: {
            height: props.showOverlay ? '80vh' : 0,
            background: setbgColor()
        },
        mobileBg: {
            display: props.showOverlay ? 'block' : 'none'
        },
        mobileMenu: {
            display: props.showOverlay ? 'grid' : 'none',
            opacity: props.showOverlay ? 1 : 0
        },
        fillSpace: {
            display: props.showOverlay ? 'block' : 'none'
        }
    };

    return (
        <div style={styles.overlay} className='menuOverlay'>
            <div style={styles.mobileMenu} className='menuMobile'>
                <NavLink activeClassName='is-active' to={ROOT}>
                    Termin buchen
                </NavLink>
                <NavLink activeClassName='is-active' to={''}>
                    Aktuelles
                </NavLink>
                <NavLink activeClassName='is-active' to={''}>
                    Für Ärzte
                </NavLink>
                <NavLink activeClassName='is-active' to={''}>
                    Jetzt Testen
                </NavLink>
                <div>info@dr-flex.de</div>
                <div>02587 979 97 90</div>
            </div>
            <img
                style={styles.mobileBg}
                className='bg bottom'
                src={require('../assets/images/backgrounds/welle_1.png')}
                alt=''
            />
            <div className='fill' style={styles.fillSpace}></div>
        </div>
    );
};

class Header extends React.Component<HeaderProps> {
    state: HeaderState = {
        isOpen: false,
        showSubmenu: false
    };

    componentDidMount() {
        document.body.style.overflow = 'scroll';
    }

    handleClick() {
        this.setState({ isOpen: !this.state.isOpen }, () => {
            if (this.state.isOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'scroll';
            }
        });
    }

    render() {
        return (
            <header className={this.props.showSearchInput ? 'header-portal' : 'default'}>
                <NavLink to='/' className='brand'>
                    <div className='icon-logo-white'></div>
                    <div className='brand_title'>Dr. Flex</div>
                </NavLink>

                {this.props.showSearchInput && (
                    <input type='text' name='search' placeholder='Zahnarzt in Berlin' onChange={this.props.onSearch} />
                )}

                <div className='menu'>
                    <NavLink activeClassName='is-active' exact to={ROOT}>
                        Termin buchen
                    </NavLink>
                    <NavLink activeClassName='is-active' exact to={''}>
                        Aktuelles
                    </NavLink>
                    <div onMouseLeave={() => this.setState({ showSubmenu: false })}>
                        <NavLink
                            activeClassName='is-active'
                            exact
                            to={''}
                            onMouseEnter={() => this.setState({ showSubmenu: true })}
                        >
                            Für Ärzte
                        </NavLink>
                        <Submenu isOpen={this.state.showSubmenu} onLeave={() => null} />
                    </div>
                    <NavLink activeClassName='is-active' exact to={''}>
                        Jetzt Testen
                    </NavLink>
                </div>
                <MenuButton open={this.state.isOpen} onClick={() => this.handleClick()} />

                <Overlay showOverlay={this.state.isOpen} />
            </header>
        );
    }
}

export default Header;
