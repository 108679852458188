import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import L from 'leaflet';

import { Customer } from '../models/customer.model';
import { DOCTOR } from '../constants/Path';

interface State {
    activeMarker: any;
    isFullScreen: boolean;
    bounds: Array<any>;
    mobileOffsetPadding: [number, number];
}

interface Props {
    customers: Customer[];
    handleMinimizeMap: () => void;
    handleMobileMapClick: () => void;
}

class Map extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activeMarker: null,
            isFullScreen: false,
            bounds: [],
            mobileOffsetPadding: [50, window.innerHeight * 0.1]
        };
    }

    map: L.Map = {} as L.Map;
    markers: Array<L.Marker> = [];

    initMap() {
        // create map

        this.map = L.map('map', {
            layers: [
                L.tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png', {
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                })
            ],
            zoomControl: false, //hide default controls
            scrollWheelZoom: window.innerWidth > 800 ? false : true
        });

        // set controls at topright
        L.control
            .zoom({
                position: 'topright'
            })
            .addTo(this.map);

        // add markers
        this.addMarkers();

        this.map.on('click', e => this.handleOnMapClick(e));
    }

    addMarkers() {
        this.props.customers.map((c, index) => {
            let latitude = c.address.latitude;
            let longitude = c.address.longitude;

            let marker = L.marker([latitude, longitude], { icon: this.getInactiveIcon() }).on('click', () => {
                this.removeActiveIcon();
                marker.setIcon(this.getActiveIcon());
                this.setState({ activeMarker: marker });
            });

            marker.bindPopup(this.renderPopup(c), {
                closeButton: false,
                offset: [170, 140]
            });
            this.markers.push(marker);

            this.map.addLayer(this.markers[index]);
            this.state.bounds.push(L.latLng(latitude, longitude));
        });

        // set bounds
        let offset: [number, number] = window.innerWidth > 800 ? [window.innerWidth * 0.47, 0] : [50, 40];
        this.map.fitBounds(this.state.bounds, { paddingTopLeft: offset });
    }

    removeCurrentMarkers() {
        if (this.markers.length > 0) {
            for (let i = 0; i < this.markers.length; i++) {
                this.map.removeLayer(this.markers[i]);
            }
        }
    }

    componentDidMount() {
        this.initMap();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.customers !== prevProps.customers) {
            this.removeCurrentMarkers();
            this.addMarkers();

            setTimeout(() => {
                this.map.invalidateSize();
            }, 200);
        }
    }

    removeActiveIcon() {
        let { activeMarker } = this.state;

        if (activeMarker !== null) {
            activeMarker.setIcon(this.getInactiveIcon());
            activeMarker = null;
        }
    }

    getInactiveIcon(): L.Icon {
        return L.icon({
            iconUrl: require('../assets/icons/marker_inactive.svg')
        });
    }

    getActiveIcon() {
        return L.icon({
            iconUrl: require('../assets/icons/marker_active.svg')
        });
    }

    renderPopup = (c: Customer) => {
        return ReactDOMServer.renderToString(
            <div className='popup-container'>
                <h4 className='name'>{c.name}</h4>
                <img className='brand' src={c.brandImage} alt={c.name + ' Logo'} />
                <div className='appointments'>
                    {c.appointments.map((a, index) => (
                        <div key={index}>{moment(a).format('D.MM.')}</div>
                    ))}
                </div>
                <div className='divider'></div>
                <a href={`${DOCTOR}/${c.customerID}`} className='primary_btn ghost-green'>
                    Zur Terminbuchung
                </a>
            </div>
        );
    };

    resetView() {
        this.map.fitBounds(this.state.bounds, { paddingBottomRight: this.state.mobileOffsetPadding });
        this.map.invalidateSize();
    }

    handleOnMapClick(e: any) {
        let map = e.target as L.Map;

        this.removeActiveIcon();

        if (window.innerWidth <= 800 && !this.state.isFullScreen) {
            setTimeout(() => {
                map.fitBounds(this.state.bounds, { paddingTopLeft: this.state.mobileOffsetPadding });
                map.invalidateSize();
            }, 200);
            this.setState({ isFullScreen: true });
            this.props.handleMobileMapClick();
        }
    }

    onMinimizemap() {
        this.props.handleMinimizeMap();
        this.setState({ isFullScreen: false });
        setTimeout(() => {
            this.map.invalidateSize();
        }, 200);
    }

    render() {
        return (
            <div className='map-container'>
                <div
                    id='map'
                    className={`${
                        this.state.isFullScreen ? 'map fullscreen' : 'map'
                    } leaflet-containerz leaflet-touch leaflet-retina leaflet-safari leaflet-fade-anim leaflet-container leaflet-grab leaflet-touch-drag leaflet-touch-zoom`}
                ></div>
                {this.state.isFullScreen && (
                    <div className='map-button-group'>
                        <button className='primary_btn white' onClick={() => this.resetView()}>
                            In diesem Gebiet suchen
                        </button>
                        <img
                            src={require('../assets/icons/minimize.svg')}
                            alt=''
                            className='primary_btn icon'
                            onClick={() => this.onMinimizemap()}
                        />
                    </div>
                )}
                {this.props.children}
            </div>
        );
    }
}

export default Map;
