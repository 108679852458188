import { Customer, MedicalSpecialityEnum } from '../models/customer.model';
import { CustomerDetails } from '../models/customerDetails.model';
import { MedicalSpeciality } from '../models/filterOptions.model';

export class FakeDb {
    public static customers: Customer[] = [
        {
            id: 1,
            customerID: 11111,
            name: 'MVZ Zahnärzte Düsseldorf Zentrum',
            address: {
                street: 'Grafenberger Allee',
                number: '136',
                zipCode: '40237',
                city: 'Düsseldorf',
                latitude: 52.532387,
                longitude: 13.396238
            },
            contact: {
                email: 'info@mvz-zahnärzte.de',
                telephone: '030-1234567',
                website: 'https://www.mvz-zahnärzte.de'
            },
            appointments: [
                '2019-11-05T00:00:00.000Z',
                '2019-11-06T00:00:00.000Z',
                '2019-11-13T00:00:00.000Z',
                '2019-11-15T00:00:00.000Z',
                '2019-11-23T00:00:00.000Z'
            ],
            brandImage: require('../assets/images/testimonials/Logo_1.png'),
            medicalSpeciality: MedicalSpecialityEnum.ZAHNMEDIZIN
        },
        {
            id: 2,
            customerID: 22222,
            name: 'Alldent Zahnzentrum Frankfurt',
            address: {
                street: 'Kaiserstraße',
                number: '1',
                zipCode: '60311',
                city: 'Frankfurt am Main',
                latitude: 52.52988,
                longitude: 13.458009
            },
            contact: {
                email: 'info@mvz-zahnärzte.de',
                telephone: '030-1234567',
                website: 'https://www.mvz-zahnärzte.de'
            },
            appointments: [
                '2019-11-05T00:00:00.000Z',
                '2019-11-06T00:00:00.000Z',
                '2019-11-13T00:00:00.000Z',
                '2019-11-15T00:00:00.000Z',
                '2019-11-23T00:00:00.000Z'
            ],
            brandImage: require('../assets/images/testimonials/Logo_2.png'),
            medicalSpeciality: MedicalSpecialityEnum.ZAHNMEDIZIN
        },
        {
            id: 3,
            customerID: 3333,
            name: 'Dr. med. Angela Birkhold',
            address: {
                street: 'Nuefferstraße',
                number: '57',
                zipCode: '66953',
                city: 'Pirmasens',
                latitude: 52.503556,
                longitude: 13.402758
            },
            contact: {
                email: 'info@mvz-zahnärzte.de',
                telephone: '030-1234567',
                website: 'https://www.mvz-zahnärzte.de'
            },
            appointments: [
                '2019-11-05T00:00:00.000Z',
                '2019-11-06T00:00:00.000Z',
                '2019-11-13T00:00:00.000Z',
                '2019-11-15T00:00:00.000Z',
                '2019-11-23T00:00:00.000Z'
            ],
            brandImage: require('../assets/images/testimonials/Logo_3.png'),
            medicalSpeciality: MedicalSpecialityEnum.ZAHNMEDIZIN
        }
    ];

    public static customerDetails: CustomerDetails[] = [
        {
            ...FakeDb.customers[0],
            aboutUs:
                'Unser Standort im Zentrum von Düsseldorf liegt äußerst verkehrsgünstig gelegen für Patienten aus dem ganzen Stadtgebiet an der Kreuzung der Hauptverkehrsstraßen Lindemannstraße und Grafenberger Allee zwischen Wehrhahn und Flingern-Nord. Vereinbaren Sie telefonisch unter 0211 – 601 13 11 oder online einen Termin (nennen Sie uns Ihren Terminwunsch und ggfs. Ihren Wunsch-Zahnarzt). Wir würden uns freuen, Sie schon bald persönlich begrüßen zu dürfen. Unser Team und Ihr Zahnarzt in Düsseldorf freuen sich auf Sie.',
            openingHours: [
                {
                    weekday: 'Montag',
                    start: '2019-01-01T08:00:00.00Z',
                    end: '2019-01-01T18:00:00.00Z'
                },
                {
                    weekday: 'Dienstag',
                    start: '2019-01-01T08:00:00.000Z',
                    end: '2019-01-01T18:00:00.000Z'
                },
                {
                    weekday: 'Mittwoch',
                    start: '2019-01-01T10:00:00.000Z',
                    end: '2019-01-01T20:00:00.000Z'
                },
                {
                    weekday: 'Donnerstag',
                    start: '2019-01-01T08:00:00.000Z',
                    end: '2019-01-01T19:00:00.000Z'
                },
                {
                    weekday: 'Freitag',
                    start: '2019-01-01T09:00:00.000Z',
                    end: '2019-01-01T18:00:00.000Z'
                }
            ],
            consultingTime: [
                { weekday: 'Dienstag', start: '2019-01-01T14:00:00.000Z', end: '2019-01-01T17:00:00.000Z' },
                {
                    weekday: 'Donnerstag',
                    start: '2019-01-01T09:00:00.000Z',
                    end: '2019-01-01T12:00:00.000Z'
                }
            ],
            doctors: [
                {
                    name: 'Prof. Dr. med. Anna Fali',
                    job: 'Zahnarzt',
                    image: require('../assets/images/6620'),
                    text:
                        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam'
                },
                {
                    name: 'Dr. Dr. med. Gunther Acknermann',
                    job: 'Zahnarzt',
                    image: require('../assets/images/6620'),
                    text:
                        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam'
                },
                {
                    name: 'Dr. Jacqueline Asong',
                    job: 'Zahnarzt',
                    image: require('../assets/images/6620'),
                    text:
                        'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam'
                }
            ],
            themeColor: '#92b933',
            surgeryImages: [
                require('../assets/images/schultz-langerhans-afrouz-img-galerie-06.jpg'),
                require('../assets/images/schultz-langerhans-afrouz-img-galerie-01.jpg'),
                require('../assets/images/schultz-langerhans-afrouz-img-galerie-02.jpg'),
                require('../assets/images/schultz-langerhans-afrouz-img-galerie-03.jpg'),
            ]
        }
    ];

    public static medicalSpecialities: MedicalSpeciality[] = [
        {
            id: 0,
            label: '-keine Auswahl-',
            value: ''
        },
        {
            id: 1,
            label: 'Zahnmedizin',
            value: 'Zahnmedizin'
        },
        {
            id: 2,
            label: 'Orthopäde',
            value: 'Orthopäde'
        },
        {
            id: 3,
            label: 'Frauenarzt',
            value: 'Frauenarzt'
        },
        {
            id: 4,
            label: 'Akupunktur',
            value: 'Akupunktur',
        },
        {
            id: 5,
            label: 'Fußchirurg',
            value: 'Fußchirurg'
        }
    ];
}
