import React from 'react';

interface Props {
    images: string[];
}

interface State {
    selectedImage: number;
    scrollTop: number;
}

class ImageGallery extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedImage: 0,
            scrollTop: -1,
        };
    }

    onScroll = (e: any) => {
        this.setState({ scrollTop: e.target.scrollTop})
    }

    render() {
        return (
            <div className='image-gallery'>
                <div className={`thumbnails ${this.state.scrollTop > 0 ? 'scrolled' : ''}`}  onScroll={e => this.onScroll(e)} >
                    {this.props.images.map((image, i) => (
                        <img key={i} src={image} alt='' onClick={() => this.setState({ selectedImage: i })}/>
                    ))}
                </div>
                <div className='selected-image'>
                    <img src={this.props.images[this.state.selectedImage]} alt=""/>
                </div>
            </div>
        );
    }
}

export default ImageGallery;
