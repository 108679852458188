import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Map from '../components/Map';
import SearchBar from '../components/SearchBar';
import Seals from '../components/Seals';
import SurgeryCard from '../components/SurgeryCard';

import { FakeDb } from '../FakeDB/fakedb';
import { Customer } from '../models/customer.model';
import { DOCTOR } from '../constants/Path';

const MAP = 'map';
const GRID = 'grid';

interface Props {}

interface State {
    customers: Customer[];
    currentView: string;
    isFullScreen: boolean;
}

class Appointments extends React.Component<Props & RouteComponentProps, State> {
    state = {
        customers: FakeDb.customers,
        currentView: GRID,
        isFullScreen: false
    };

    componentDidMount() {
        // get customers
    }

    onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.currentTarget.value);
    };

    handleFilterChange = (value: string) => {
        console.log(value);
    };

    render() {
        return (
            <div className='portal'>
                <section className='hero'>
                    <Header showSearchInput={true} onSearch={e => this.onSearch(e)} />
                    <img className='bg bottom' src={require('../assets/images/backgrounds/welle_1.png')} alt='' />
                </section>

                <div className='search-wrapper'>
                    <input
                        className='search'
                        type='text'
                        name='search'
                        placeholder='Zahnarzt in Berlin'
                        onChange={this.onSearch}
                    />
                </div>

                <SearchBar
                    customers={this.state.customers}
                    currentView={this.state.currentView}
                    onChangeView={view => this.setState({ currentView: view })}
                    onChangeFilter={value => this.handleFilterChange(value)}
                />

                <div className='divider' style={{ marginBottom: this.state.currentView === MAP ? 0 : '1.875em' }}></div>

                {this.state.currentView === MAP && (
                    <Map
                        customers={this.state.customers}
                        handleMinimizeMap={() => this.setState({ isFullScreen: false })}
                        handleMobileMapClick={() => this.setState({ isFullScreen: true })}
                    >
                        <div className='result-mobile'>
                            {this.state.customers.length} Ärzte <span className='city'>in Berlin gefunden</span>
                        </div>
                        <div className={`card-overlay ${this.state.isFullScreen ? 'fullscreen' : ''}`}>
                            {this.state.customers.map((c, i) => (
                                <SurgeryCard
                                    key={i}
                                    customer={c}
                                    onClickCard={() => this.props.history.push(`${DOCTOR}/${c.customerID}`)}
                                />
                            ))}
                        </div>
                    </Map>
                )}

                {this.state.currentView === GRID && (
                    <div className='grid-container'>
                        <div className='result-mobile grid'>
                            {this.state.customers.length} Ärzte <span className='city'>in Berlin gefunden</span>
                        </div>

                        {this.state.customers.map((c, i) => (
                            <SurgeryCard
                                key={i}
                                customer={c}
                                onClickCard={() => this.props.history.push(`${DOCTOR}/${c.customerID}`)}
                            />
                        ))}
                    </div>
                )}

                {!this.state.isFullScreen && (
                    <React.Fragment>
                        <Seals />
                        <Footer />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default Appointments;
