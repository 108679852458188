import 'react-app-polyfill/ie11'; //ie11 react support
//import 'react-app-polyfill/ie9'; //ie9 react support
import 'react-app-polyfill/stable';
// https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

 