import React from 'react';
import { useEffect } from 'react';
import { Route, BrowserRouter, useLocation } from 'react-router-dom';

import Portal from './pages/Portal';
import DocotorPage from './pages/DoctorPage';

import {
    ROOT, DOCTOR,
} from './constants/Path';

import './scss/App.scss';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <div id='main' className='app'>
                <Route exact path={ROOT} component={Portal} />
                <Route exact path={`${DOCTOR}/:id`} component={DocotorPage} />
            </div>
        </BrowserRouter>
    );
};

export default App;
