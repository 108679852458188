import React from 'react';
import { CustomerDetails } from '../models/customerDetails.model';

import moment from 'moment';

interface Props {
    customer: CustomerDetails;
    color: string
}

class DoctorOverview extends React.Component<Props> {
    render() {
        const { customer, color } = this.props;
        return (
            <div className='overview'>
                <h3>{customer.name}</h3>
                <img src={customer.brandImage} alt='' />
                <div className='address'>{`${customer.address.street} ${customer.address.number}, ${customer.address.zipCode} ${customer.address.city}`}</div>

                <div>
                    <div>Unsere Öffnungszeiten</div>
                    {customer.openingHours.map((o, i) => (
                        <div key={i}>{`${o.weekday}: ${moment(o.start).format('HH:mm')} - ${moment(o.end).format(
                            'HH:mm'
                        )}`}</div>
                    ))}
                </div>

                <div>
                    <div>Unsere Sprechzeiten</div>
                    {customer.consultingTime.map((o, i) => (
                        <div key={i}>{`${o.weekday}: ${moment(o.start).format('HH:mm')} - ${moment(o.end).format(
                            'HH:mm'
                        )}`}</div>
                    ))}
                </div>

                <div>
                    <div>Website:</div>
                    <a
                        style={{ color: color }}
                        href={customer.contact.website}
                        rel="noopener noreferrer"
                        target='_blank'
                    >
                        {customer.contact.website.replace(/(^\w+:|^)\/\//, '')}
                    </a>

                    <div>Email:</div>
                    <a style={{ color: color }} href={`mailto:${customer.contact.email}`}>
                        {customer.contact.email}
                    </a>

                    <div>Telefon:</div>
                    <a style={{ color: color }} href={`tel:${customer.contact.telephone}`}>
                        {customer.contact.telephone}
                    </a>
                </div>
            </div>
        );
    }
}

export default DoctorOverview;
