import React from 'react';
import { Customer } from '../models/customer.model';

import Dropdown from '../components/Dropdown';
import { MedicalSpeciality } from '../models/filterOptions.model';
import { FakeDb } from '../FakeDB/fakedb';

const mapActive = require('../assets/icons/map_active.svg');
const mapInactive = require('../assets/icons/map_inactive.svg');
const gridActive = require('../assets/icons/grid_active.svg');
const gridInactive = require('../assets/icons/grid_inactive.svg');

const MAP = 'map';
const GRID = 'grid';

interface Props {
    customers: Customer[];
    currentView: string;
    onChangeView: (type: string) => void;
    onChangeFilter: (type: string) => void;
}

interface State {
    medicalSpecialities: MedicalSpeciality[];
}

class SearchBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            medicalSpecialities: FakeDb.medicalSpecialities
        };
    }

    render() {
        return (
            <div className='searchbar'>
                <div className='result'>
                    {this.props.customers.length} Ärzte <span className='city'>in Berlin gefunden</span>
                </div>

                <Dropdown
                    options={this.state.medicalSpecialities}
                    placeholder='Fachrichtung'
                    onChange={value => this.props.onChangeFilter(value)}
                />

                <Dropdown
                    options={this.state.medicalSpecialities}
                    placeholder='Sprechstunde'
                    onChange={value => this.props.onChangeFilter(value)}
                />

                <Dropdown
                    options={this.state.medicalSpecialities}
                    placeholder='Akutsprechstunde'
                    onChange={value => this.props.onChangeFilter(value)}
                />

                <div className='view-options'>
                    <img
                        src={this.props.currentView === GRID ? gridActive : gridInactive}
                        alt=''
                        onClick={() => this.props.onChangeView(GRID)}
                    />
                    <div className='divider-vertical'></div>
                    <img
                        src={this.props.currentView === MAP ? mapActive : mapInactive}
                        alt=''
                        onClick={() => this.props.onChangeView(MAP)}
                    />
                </div>
            </div>
        );
    }
}

export default SearchBar;
