import React from 'react';

interface Props {
    options: Array<any>;
    placeholder?: string;
    onChange: (type: string) => void;
}

interface State {
    showOptions: boolean;
    label: string;
    isFocused: boolean;
}

class Dropdown extends React.Component<Props, State> {
    state: State = {
        showOptions: false,
        label: '',
        isFocused: false
    };

    setLabel = (option: string) => {
        this.setState({ label: option, showOptions: false });
    };

    handleToggle = (e: any) => {
        e.target.focus();
        this.setState(prevState => ({ showOptions: !prevState.showOptions, isFocused: true }));
    };

    handleBlur = (e: any) => {
        if (this.state.showOptions) {
            setTimeout(() => {
                this.setState({ showOptions: false, isFocused: false });
            }, 200);
        }
    };

    handleChange = (value: string) => {
        this.setState({ label: value });
        this.props.onChange(value);
    };

    render() {
        const { isFocused, label, showOptions } = this.state
        return (
            <div className={`dropdown-container ${label !== '' || isFocused ? 'selected' : ''}`}>
                <input
                    className='dropdown'
                    value={label}
                    placeholder={this.props.placeholder}
                    onClick={e => this.handleToggle(e)}
                    onBlur={e => this.handleBlur(e)}
                    readOnly
                />

                {showOptions && (
                    <div className='optionList'>
                        {this.props.options.map(o => (
                            <button key={o.id} onClick={() => this.handleChange(o.value)}>
                                {o.label}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default Dropdown;
