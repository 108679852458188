import React from 'react';
import { Doctor } from '../models/customerDetails.model';

interface Props {
    doctor: Doctor;
    backgroundColor: string;
}

interface State {
    showText: boolean;
}

class DoctorCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showText: false
        };
    }

    render() {
        const { doctor, backgroundColor } = this.props;
        return (
            <div
                className='doctor-card'
                onClick={() => this.setState(prevState => ({ showText: !prevState.showText }))}
            >
                <img src={doctor.image} alt='' />
                <div>
                    <p className='name'>{doctor.name}</p>
                    <p className='job'>{doctor.job} </p>
                </div>
                <img
                    style={{ background: backgroundColor }}
                    className={`icon ${!this.state.showText ? '' : 'active'}`}
                    src={require('../assets/icons/arrow-down.svg')}
                    alt=''
                />
                <div className={`text ${!this.state.showText ? 'inactive' : 'active'}`}>
                    <p>{doctor.text}</p>
                </div>
            </div>
        );
    }
}

export default DoctorCard;
